<template>
  <div class="agreement" v-html="html"></div>
    
</template>
<script>
import config from "@/common/config/user/agreement.config.js";
export default {
  data() {
    return {
     config,
     html:''
    };
  },
  activated(){
    const index=this.$route.query.index
    this.html=config.agreements[index].html
  }
};
</script>
<style lang="scss" scoped>
.agreement{
  margin: 20px;
}
</style>