export default {
	agreements: [{
			name: '云淘通用户注册协议',
			html: `<div class="Section0" style="layout-grid:15.6000pt;"><p class="MsoNormal" align="center" style="text-align:center;"><span style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><font face="微软雅黑">用户注册协议</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:1.0000pt;background:rgb(255,255,255);mso-shading:rgb(255,255,255);"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">版本更新日期：</font>202</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">年</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">月</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">18</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">日</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">版本生效日期：</font>2020年</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">月</font>1</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">8</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">日</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">一、服务条款的确认及接受</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站（指</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">yuntaotong.com</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">及其移动客户端软件、应用程序</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">公众号</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">，以下称</font>“本网站”）各项电子服务的所有权和运作权归属于“</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">”所有，本网站提供的服务将完全按照其发布的服务条款和操作规则严格执行。您确认所有服务条款并完成注册程序时，本协议在您与本网站间成立并发生法律效力，同时您成为本网站正式</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">会员</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">用户。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、根据国家法律法规变化及本网站运营需要，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权对本协议条款及相关规则不时地进行修改，修改后的内容一旦以任何形式公布在本网站上即生效，并取代此前相关内容，您应不时关注本网站公告、提示信息及协议、规则等相关内容的变动。您知悉并确认，如您不同意更新后的内容，应立即停止使用本网站；如您继续使用本网站，即视为知悉变动内容并同意接受。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、本协议内容中以加粗方式显著标识的条款，请您着重阅读。您点击“同意”按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">二、服务须知</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、本网站运用自身开发的操作系统通过国际互联网络为用户提供</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商品等服务。使用本网站，您必须：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）自行配备上网的所需设备，包括个人手机、平板电脑、调制解调器、路由器等；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）自行负担个人上网所支付的与此服务有关的电话费用、网络费用等；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）选择与所安装终端设备相匹配的软件版本，包括但不限于iOS、Android、Windows等多个</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">将来云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">发布的应用版本。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、基于本网站所提供的网络服务的重要性，您确认并同意：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，应及时更新；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）如果您提供的注册资料不合法、不真实、不准确、不详尽的，您需承担因此引起的相应责任及后果，并且</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通有</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">保留终止您使用本网站各项服务的权利。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">三、订单</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、使用本网站下订单，您应具备</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商品相关</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">常识</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的权利能力和行为能力，如果您在</font>18周岁以下，您需要在监护人的监护参与下才能注册并使用本网站。在下订单的同时，即视为您满足上述条件，并对您在订单中提供的所有信息的真实性负责。</span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、在您下订单时，请您仔细确认所</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与您本人不一致的，收货人的行为和意思表示视为您的行为和意思表示，您应对收货人的行为及意思表示的法律后果承担连带责任。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、您理解并同意：本网站上展示的商品和</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">价等信息仅仅是要约邀请，您下单时须填写您希望</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品数量、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">价及支付方式、收货人、联系方式、收货地址（合同履行地点）、合同履行方式等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售商发出的合同要约；</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商收到您的订单信息后，在</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商将您在订单中</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品从仓库实际直接</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">或间接</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">向您发出时（以商品出库为标志），视为您与</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商之间就实际直接向您发出的商品建立了合同关系；如果您在一份订单里订购了多种商品并且</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商只给您发出了部分商品时，您与</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商之间仅就实际直接向您发出的商品建立了合同关系，只有在</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商实际直接向您发出了订单中订购的其他商品时，您和</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商之间就订单中其他已实际直接向您发出的商品才成立合同关系；对于</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">金融、金融衍生产品、国外的商品、散装的农鱼副食、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">电子书、数字音乐、在线手机充值等数字化商品，您下单并</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">支付货款后</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">无法</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">成立合同。当您作为</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">会员</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">消费者为生活消费需要下单并</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">支付</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的情况下，您</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">支付成功后即视为您与</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商之间就已</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">支付</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">部分的订单建立了合同关系。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;text-align:left;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			mso-ansi-font-weight:bold;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">4、尽管供应商做出最大的努力，但由于市场变化及各种以合理商业努力难以控制因素的影响，本网站无法避免您提交的订单信息中的商品出现缺货、价格标示错误等情况；如您下单所兑换的商品出现以上情况，您有权取消订单，供应商亦有权依法、依约取消订单，若您已经兑换支付，则为您办理退积分，并提供订单处理方案。</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			mso-ansi-font-weight:bold;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;text-align:left;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;letter-spacing:0.0000pt;
			mso-ansi-font-weight:bold;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><o:p>&nbsp;</o:p></span></b></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">四、配送和交付</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、您在本</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品将按照本网站上您所指定的送货地址进行配送。订单信息中列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。您应当清楚准确地填写您的送货地址、联系人及联系方式等配送信息，您知悉并确认，您所</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品应仅由您填写的联系人接受身份查验后接收商品，因您变更联系人或相关配送信息</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">不真实、错误</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">而造成的损失由您自行承担。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、因如下情况造成订单延迟或无法配送等，本</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站将无法承担迟延配送或无法配送的责任：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）客户提供错误信息和不详细的地址；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）货物送达无人签收或拒收，由此造成的重复配送所产生的费用及相关的后果。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）不可抗力，例如：自然灾害及恶劣天气、交通戒严等政府、司法机关的行为、决定或命令、意外交通事故、罢工、法规政策的修改、恐怖事件、抢劫、抢夺等暴力犯罪、突发战争等。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、您在本</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品由</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的关联方或第三方配送公司（包括</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">京东、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">顺丰、圆通等，以下称</font>“配送公司”）为您完成订单交付的，系统或单据记录的签收时间为交付时间；您</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的商品采用在线传输方式交付的，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">供应</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">商向您指定系统发送的时间为交付时间；您</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">服务的，生成的电子或者实物凭证中载明的时间为交付时间。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">五、用户个人信息保护及授权</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、您知悉并同意，为方便您使用本</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站相关服务，本网站将存储您在使用时的必要信息，包括但不限于您的真实姓名、性别、生日、配送地址、联系方式等。除法律法规规定的情形外，未经您的许可</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">不会向第三方公开、透露您的个人信息。</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">对相关信息采取专业加密存储与传输方式，利用合理措施保障用户个人信息的安全。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、您知悉并确认，您在注册帐号或使用本网站</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">在线充值</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的过程中，需要提供真实的身份信息，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">将根据国家法律法规相关要求，进行基于移动电话号码的真实身份信息认证。若您提供的信息不真实、不完整，则无法使用本网站或在使用过程中受到限制，同时，由此产生的不利后果，由您自行承担。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、您在使用本网站某一特定服务时，该服务可能会另有单独的协议、相关业务规则等（以下统称为“单独协议”），您在使用该项服务前请阅读并同意相关的单独协议；您使用前述特定服务，即视为您已阅读并同意接受相关单独协议。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);">4、您充分理解并同意：</span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）接收通过邮件、短信、电话等形式，向在本网站注册、</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">兑换</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">购物的用户、收货人发送的订单信息、促销活动等内容；</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）为配合行政监管机关、司法机关执行工作，在法律规定范围</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">内云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权向上述行政、司法机关提供您在使用本网站时所储存的相关信息，包括但不限于您的注册信息等，或使用相关信息进行证据保全，包括但不限于公证、见证等；</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">依法保障您在使用过程中的知情权和选择权，在您使用本网站服务过程中，涉及您设备自带功能的服务会提前征得您同意，您一经确认，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权开启包括但不限于收集地理位置、读取通讯录、使用摄像头、启用录音等提供服务必要的辅助功能。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>4）</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权根据实际情况，在法律规定范围内自行决定单个用户在本网站及服务中数据的最长储存期限以及用户日志的储存期限，并在服务器上为其分配数据最大存储空间等。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">六、用户行为规范</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、您同意严格遵守法律法规规章规定，依法遵守以下义务：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）不得制作、传输或发表以下违法信息资料：</span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律法规实施的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动推翻社会主义制度的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬或煽动实施恐怖主义、极端主义及其活动的；煽动民族仇恨、民族歧视、破坏民族团结的言论；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、暴力或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）防范和抵制制作、复制、发布含有下列内容的不良信息资料：</span></b><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">标题严重夸张，发表内容与标题严重不符的；不当评述自然灾害、重大事故等灾难的；煽动人群歧视、地域歧视等的；宣扬低俗、庸俗、媚俗内容的；违反社会公德行为的；侵犯未成年人合法权益的；其他对网络生态造成不良影响的内容。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);">&nbsp;2、本协议依据国家相关法律法规规章制定，您亦同意严格遵守以下义务：</span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）从中国大陆向境外传输资料信息时必须符合中国有关法规；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）不得利用本网站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）不得干扰本网站的正常运转，不得侵入本网站及国家计算机信息系统；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>4）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息资料；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>5）不得教唆他人从事违法违规或本协议、平台规则所禁止的行为；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>6）不得利用在本网站注册的账户进行牟利性经营活动；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>7）不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="justify" style="text-indent:24.0000pt;mso-char-indent-count:2.0000;text-align:justify;
			text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);">3、您须对自己在网上的言论和行为承担法律责任，您若在本网站上散布和传播反动、色情或其它违反国家法律的信息，本网站的系统记录有可能作为您违反法律的证据。</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><o:p></o:p></span></b></p><p class="MsoNormal" align="justify" style="text-indent:24.0000pt;mso-char-indent-count:2.0000;text-align:justify;
			text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><o:p>&nbsp;</o:p></span></b></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">七、本网站使用规范</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、关于移动客户端软件的获取与更新：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）您可以直接从</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站上获取</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">界面</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通目前没有</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">授权</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">任何</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">第三方获取，如果您从未经</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">授权的第三方获取</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">登录</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">或与</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">名称相同的安装程序，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">无法保证该</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">能够正常使用，并对因此给您造成的损失不予负责；</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）为了改善用户体验、完善服务内容，</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">将不断努力开发新的服务，并为您不时提供</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">更新，新版本发布后，旧版本的</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">可能无法使用，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">不保证旧版本</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">平台</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">继续可用及相应的客户服务，请您随时核对并</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">体验</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">最新版本。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、除非法律允许或</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">书面许可，您使用本网站过程中不得从事下列行为：</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>1）删除本网站及其副本上关于著作权的信息；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>2）对本网站进行反向工程、反向汇编、反向编译，或者以其他方式尝试发现本网站的源代码；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>3）对</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">拥有知识产权的内容进行使用、出租、出借、复制、修改、链接、转载、汇编、发表、出版、建立镜像站点等；</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>4）对本网站或者本网站运行过程中释放到任何终端内存中的数据、网站运行过程中客户端与服务器端的交互数据，以及本网站运行所必需的系统数据，进行复制、修改、增加、删除、挂接运行或创作任何衍生作品，形式包括但不限于使用插件、外挂或非经</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">授权的第三方工具</font>/服务接入本网站和相关系统；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>5）通过修改或伪造网站运行中的指令、数据，增加、删减、变动网站的功能或运行效果，或者将用于上述用途的软件、方法进行运营或向公众传播，无论这些行为是否为商业目的；</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>6）通过非</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">开发、授权的第三方软件、插件、外挂、系统，登录或使用本网站及服务，或制作、发布、传播上述工具；</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">（</font>7）自行或者授权他人、第三方软件对本网站及其组件、模块、数据进行干扰。</span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">八、违约责任</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、如果</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">发现或收到他人举报投诉您违反本协议约定或存在任何恶意行为的，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、限制或禁止使用部分或全部功能、帐号封禁、注销等处罚，并公告处理结果。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权依据合理判断对违反有关法律法规或本协议规定的行为采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等，您应独自承担由此而产生的一切法律责任。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、您理解并同意，因您违反本协议或相关服务条款的规定，导致或产生第三方主张的任何索赔、要求或损失，您应当独立承担责任；</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">因此遭受损失的，您也应当一并赔偿。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">4、除非另有明确的书面说明</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">不对本网站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">九、所有权及知识产权</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、您一旦接受本协议，对于您提供、上传、创作、发布在本网站的文字、评论、图片、照片、视频、音频等任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等），您免费授予</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">及其关联公司至保护期终止为止、不可撤销的、全球范围的、排他的许可使用著作权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可许可使用的权利）、肖像权、用户</font>ID名称等相关的全部合法权利权益，就</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">具有诉讼主体资格的权利、权益，</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权以自身名义对第三方侵权行为取证及提起诉讼。您同意</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">及其关联公司有权存储、使用、复制、修订、编辑、发布、展示、翻译、分发、推广、出版、发行、信息网络传播您以上信息内容，有权改编制作派生作品，并以已知或日后开发的形式、媒体或技术将上述信息纳入其它作品内。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权再许可给其他第三方第九条第一款的所有权利。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、您应确保在</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">网站上发表的第九条第一款各类信息内容均不涉及侵犯第三方肖像、隐私、知识产权或其他合法权益。否则</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权随时采取包括但不限于删除、断开链接等措施。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">4、本协议已经构成《中华人民共和国著作权法》第二十四条（条文序号依照2010年修订版《著作权法》确定）及相关法律规定的著作财产权等权利许可使用的书面协议，其效力及于您在本网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">5、您同意并已充分了解本协议的条款，承诺不将已发表于本网站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">6、除法律另有强制性规定外，未经</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">明确的特别书面同意</font>,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本网站的信息内容</span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">、</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">泄露其他会员信息，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">否则，</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">有权追究其法律责任。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">7、本网站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">或其内容提供者的财产，受中国和国际版权法的保护。本网站上所有内容的汇编是</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的排他财产，受中国和国际版权法的保护。本网站上所有软件都是</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span><span style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</font></span><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">十、法律管辖适用及其他</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">1、本协议的订立、执行和解释及争议的解决均应适用中国法律。如双方就本协议内容或其执行发生任何争议，双方应尽力友好协商解决；协商不成时，应向协议签订地有管辖权的人民法院提起诉讼。本协议签订地为中华人民共和国北京市</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">海淀区</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">2、如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</font></span></b><span style="mso-spacerun:'yes';font-family:Tahoma;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:12.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:10.0000pt;
			margin-left:0.0000pt;text-indent:21.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;background:rgb(255,255,255);"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">3、本协议未明示授权的其他权利仍由</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">保留，您在行使这些权利时须另外取得</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">的书面许可。</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="微软雅黑">如果未行使前述任何权利，并不构成对该权利的放弃。</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:微软雅黑;color:rgb(0,0,0);
			letter-spacing:0.0000pt;mso-ansi-font-weight:bold;text-transform:none;
			font-style:normal;font-size:12.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><o:p></o:p></span></b></p></div>`
		},
		{
			name: '云淘通隐私政策',
			html:`<div class="Section0" style="layout-grid:15.6000pt;"><h2 align="center" style="margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
			word-break:break-all;mso-pagination:widow-orphan;text-align:center;
			vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(32,32,32);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:18.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">《</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(32,32,32);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:18.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(32,32,32);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:18.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">隐私政策》</font></span></b></h2><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><a name="OLE_LINK1"></a><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">尊敬的云淘通积分商城会员用户：我们对《云淘通隐私政策》进行了更新。此版本《云淘通隐私政策》的更新主要集中在：</font><font face="黑体">云淘通</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">接入的第三方</font>VPO情况更新；个性化推荐管理路径更新。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">【特别提示】</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">本政策仅适用于云韬物联及其关联公司（包括但不限于云淘通</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">以下或称</font>“我们”或“云淘通”）提供的产品和服务及其延伸的功能（以下简称“云淘通服务”），包括云淘通、网站、客户端、小程序公众号以及随技术发展出现的新形态向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请仔细阅读《云淘通隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《云淘通隐私政策》中的联系方式咨询我们。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">版本更新日期：</font>2021年【01】月【18】日</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">版本生效日期：</font>2021年【02】月【18】日</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">如您有任何疑问、意见或建议，您可通过以下联系方式与我们联系：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">联系电话：</font>010-62456788</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">联系邮箱：</font>yuntaotong@yuntaowulian.com</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">注册地址：北京市海淀区北清路</font>68号院21号楼2层24室</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">常用办公地址：北京市海淀区北清路</font>68号院&nbsp;</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="center" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:center;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">引言</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">欢迎您使用云淘通！我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与</font>/或服务时，我们可能会收集和使用您的相关注册信息。我们希望通过《云淘通隐私政策》（“本隐私政策”）向您说明我们在您使用我们的产品与/或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">本隐私政策将帮助您了解以下内容：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">一、我们如何收集和使用您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">二、我们如何使用</font>&nbsp;Cookies&nbsp;和同类技术</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">三、我们如何共享、转让、公开披露您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">四、我们如何保护和保存您的个人信息</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">五、您如何管理个人信息</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">六、未成年人的个人信息保护</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">七、通知和修订</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">八、如何联系我们</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">本隐私政策与您所使用的云淘通服务以及该服务所包括的各种业务功能（以下统称</font>“我们的产品与/或服务”）息息相关，希望您在使用我们的产品与/或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您认为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您特别注意：本隐私政策适用于云淘通平台提供的产品或服务内容。如我们关联公司的产品或服务中使用了云淘通提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">一、我们如何收集和使用您提供的个人注册信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">敏感个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（我们将在本政策中对涉及到的敏感个人信息以</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">粗体</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">进行显著标识）。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与</font>/或服务过程中主动提供或因使用产品与/或服务而产生的个人信息，以及从第三方获取您的相关个人信息。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（一）您须授权我们收集和使用您个人信息的情形</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们的产品与</font>/或服务包括一些核心功能，这些功能包含了实现网上购物所必须的功能及保障交易安全所必须的功能。我们可能会收集、保存和使用下列与您有关的信息才能实现上述这些功能。如果您不提供相关信息，您将无法享受我们提供的产品与/或服务。这些功能包括：</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、实现网上兑换物品所必须的功能</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）账户注册、登录与验证</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">当您注册云淘通账户时，您至少需要向我们提供您准备使用的云淘通</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">账户名、密码、您本人的手机号码、电子邮箱地址（用于验证邮箱）</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，我们将通过发送短信验证码或邮件的方式来验证您的身份是否有效，通过</font>SIM卡信息识别并显示对应的运营商。您的账户名为您的默认昵称，您可以修改和补充您的昵称、性别、生日、兴趣爱好以及您的实名认证相关信息，这些信息均属于您的“账户信息”。您补充的账户信息将有助于我们为您提供个性化的商品推荐和更优的购物体验，但如果您不提供这些补充信息，不会影响您使用网上购物的基本功能。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">当您成功注册云淘通账号后，可以使用云淘通账号登录</font>“云淘通积分商城”，登录时您需要向我们提供您的云淘通账号信息。若您不提供这类信息，您将无法登录和在“云淘通积分商城”兑换商品，此时您可使用浏览和搜索功能，我们将不会收集您的上述个人信息。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）商品/服务信息展示和搜索</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为了让您快速地找到您所需要的商品，我们可能会收集您使用我们的产品与</font>/或服务的设备信息（包括设备名称、服务提供商网络ID（PLMN））、设备所在位置相关信息（不搜集）、浏览器类型来为您提供商品信息展示的最优方式。我们也会为了不断改进和优化上述的功能来使用您的上述个人信息。如果您不想看到推荐的商品或服务等信息，您可以通过长按被推荐的商品或服务图片，根据提示弹窗选择屏蔽相关商品或服务类目。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您也可以通过搜索来精准地找到您所需要的商品或服务。我们会保留您的搜索内容以方便您重复输入或为您展示与您搜索内容相关联的商品或服务。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您注意，单独的设备信息、浏览器类型或仅搜索关键词信息无法单独识别您的身份，不属于您的个人信息，我们有权以任何的目的对其进行使用；只有当您的单独的设备信息、浏览器类型或搜索关键词信息与您的其他信息相互结合使用并可以识别您的身份时，则在结合使用期间，我们会将您的设备信息、浏览器类型或搜索关键词信息作为您的个人信息，按照本隐私政策对其进行处理与保护。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为了将您感兴趣的商品或服务信息展示给您，或在您搜索时向您展示您可能希望找到的商品，我们可能会收集您的</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">订单信息、浏览信息、您的兴趣爱好</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（您可以在账户设置中选择）进行数据分析以形成用户图形。我们还可能为了提供服务及改进服务质量的合理需要而获得的您的其他信息，包括您与客服联系时您提供的相关信息，您参与问卷调查时向我们发送的问卷答复信息，以及您与我们的关联方、我们合作伙伴之间互动时我们获得的相关信息。对于从您的各种设备上提供到的信息，我们可能会将它们进行关联，以便我们能在这些设备上为您提供一致的服务。我们可能会将来自某项服务的信息与来自其他服务的信息结合起来，以便为您提供服务、个性化内容和建议。如果您不希望受个性化内容的影响，您可以在商品展示页选择按照分类进行浏览和查找商品和服务；我们还在搜索结果中向您提供了按照积分价格、销量排序等不针对个人特征的选项；未经您的明确同意，我们不会将您的相关个人信息提供给第三方。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）下单及订单管理</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">当您准备对您购物车内的商品进行结算兑换时，云淘通系统会生成您兑换该商品的订单。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您需要在订单中至少填写您的收货人姓名、收货地址以及手机号码，同时该订单中会载明订单号、您所购买的商品或服务信息、下单时间、您应支付的积分金额及支付方式；您可以另外填写收货人的联系电话、邮箱地址信息以增加更多的联系方式确保商品可以准确送达，但不填写这些信息不影响您订单的生成。若您符合开具发票条件，需要开具发票，还需要提供发票抬头、纳税人识别号以及接收发票电子邮箱。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在实现部分特殊类型商品兑换或服务时还需要您提供其他必要信息，</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">例如您在云淘通上办理申请节能补贴充值等业务时，您可能需要根据国家法律法规或服务提供方（包括支付功能、基础电信运营商、移动转售运营商等）的要求</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">提供您的实名信息，这些实名信息可能包括您的身份信息（比如您的出生证明、户口本、身份证、军官证、护照、驾驶证等载明您身份信息的证件复印件或号码）。这些订单中将可能包含您指定的服务地址或设备安装地址（可能是您的家庭地址）信息。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在您为手机购买意外险时，可能还需要您提供</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">手机</font>IMEI信息</span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">上述所有信息构成您的</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">“订单信息”</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，我们将使用您的订单信息来（包括与为您提供上述服务的第三方）进行您的身份核验、确定交易、支付结算、完成配送、为您查询订单以及提供客服咨询与售后服务。我们还会使用您的订单信息来判断您的交易是否存在异常以保护您的交易安全。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）兑换功能</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在您下单兑换后，您可以选择云淘通的关联方或与云淘通合作的第三方支付机构（包括支付宝、微信支付及银联、网联等支付通道，以下称</font>“支付机构”）所提供的支付服务。支付兑换站内积分功能本身并不收集您的个人信息，</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">但我们需要将您的交易金额信息与云淘通订单，包括您的银行卡号、有效期、还款金额在内的银行卡（含储蓄卡、信用卡）支付必要信息与这些支付机构共享以实现其确认您的支付指令并完成支付兑换积分，以及您开通、使用云淘通积分的账户余额信息，以便您能正常使用豆包功能。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">“关联方”指一方现在或将来控制、受控制或与其处于共同控制下的任何公司、机构以及上述公司或机构的法定代表人。“控制”是指直接或间接地拥有影响所提及公司管理的能力，无论是通过所有权、有投票权的股份、合同或其他被人民法院认定的方式。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）交付产品与/或服务功能</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在当您下单并选择以回收优惠券积分或积分充值的形式完成支付，或选择获取试用品、奖品后，云淘通、云淘通的关联方、供应商、与云淘通合作的商家或第三方配送公司（以下称</font>“配送公司”）将为您完成订单的交付。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您知晓并同意，云淘通、云淘通的关联方、供应商、与云淘通合作的商家或第三方配送公司会在上述环节内使用您的订单信息以保证您的订购的商品能够安全送达。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们的第三方配送员在为您提供配送服务的同时，</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">基于某些业务法律要求实名认证的需求，会协助您完成实名认证</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，如您购买手机等商品有其他服务时。我们在此环节会使用您的身份证通过国家有权认证机构的专有设备对您的身份进行核验，我们不会收集您的身份证信息，且我们的第三方配送员均须遵守公司保密制度的规定。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>6）客服与售后功能</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们的电话</font>QQ客服和售后功能会使用您的账号信息和订单信息。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为保证您的账号安全，我们的呼叫中心客服和在线</font>QQ客服会使用您的账号信息与您</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">核验您的身份</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">。当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">订单信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">。您有可能会在与我们的客服人员沟通时，提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">若您有获赠或购买商品运费险，您在退换货时所产生的运费可能申请由运费险服务提供商进行赔付，为实现投保和理赔，我</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">们将收集或查询您购买商品的质保起止期、退换货商品快递公司、快递单号、退货地址、投递时间信息。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、保障交易安全所必须的功能</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为保障您使用我们的产品与</font>/或服务时系统的稳定性和安全性，防止您的个人信息被非法获取，更准确地预防欺诈和保护账号安全，我们需要收集您的账号</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">信息判断您的账号风险</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，并可能会记录一些我们认为有风险的链接（</font>“URL”）；我们也会收集您的设备信息用于对云淘通系统问题进行分析、统计流量并排查可能存在的风险，在您选择向我们发送异常信息时予以排查。如果您不提供上述信息，我们则无法在您使用云淘通服务过程中对您的服务及账号安全进行保护。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（二）您可自主选择提供的个人信息的情形</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、为使您购物更便捷或更有乐趣，从而提升您在云淘通的网上兑换购物体验，我们在扩展功能时可能会收集和使用您的注册信息。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">如果您不提供这些个人信息，您依然可以进行网上购物，但您可能无法使用为您带来购物乐趣的扩展功能或在购买某些商品时需要重复填写一些信息。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您注意，您提供这些信息即代表您授权我们可以收集和使用这些个人信息来实现上述的功能，您不提供即代表您取消了这些授权，则我们将不再收集和使用您的这些信息，也无法为您提供上述与这些授权所对应的功能。您决定不会影响此前基于您的授权所进行的个人信息的处理。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">除此以外，我们在相关业务功能中可能还需要您开启设备的其他，确保第三方软件正常使用。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（三）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意：</font>&nbsp;</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、与国家安全、国防安全有关的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、与公共安全、公共卫生、重大公共利益有关的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、与犯罪侦查、起诉、审判和判决执行等有关的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">5、所收集的个人信息是个人信息主体或监护人自行向社会公众公开的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">6、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">7、根据您的要求签订合同所必需的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">8、用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">9、为合法的新闻报道所必需的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">10、学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">11、涉嫌侵害集体或个人的财产的行为；</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">12、法律法规规定的其他情形。</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（四）我们从第三方获得您个人信息的情形</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们可能从第三方获取您授权共享的</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">账户信息（头像、昵称及授权页面提示的其他信息）</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，并在您同意本隐私政策后将您的第三方账户与您的云淘通账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与</font>/或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。您也可以使用本机号码来快捷登录，我们会将您的网络信息提供给运营商完成注册或登录。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			mso-para-margin-right:0.0000gd;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">(五)云淘通涉及嵌入第三方代码、插件传输个人信息的情形逐项列举。</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			padding:0pt 0pt 0pt 0pt ;word-break:break-all;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(127,127,127);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="黑体">为保障云淘通相关功能的实现和应用的安全稳定运行，我们会接入由第三方提供的软件开发包（</font>SDK）实现该目的。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(127,127,127);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			padding:0pt 0pt 0pt 0pt ;word-break:break-all;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;background:rgb(255,255,255);"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(127,127,127);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;background:rgb(255,255,255);
			mso-shading:rgb(255,255,255);"><font face="黑体">我们会对第三方</font>SDK进行严格的安全检测，并要求合作伙伴采取严格的措施来保护您的个人数据。在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方SDK，并及时在本说明中向您公开说明接入第三方SDK的最新情况。请注意，第三方SDK可能因为版本升级、策略调整等原因导致数据类型存在一些变化，附其官方网站链接或相关隐私保护说明链接供参考，具体请以其公示的官方说明为准。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(127,127,127);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			mso-para-margin-right:0.0000gd;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（六）您个人信息使用的规则</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、我们会根据本隐私政策的约定并为实现我们的产品与/或服务功能对所收集的个人信息进行使用。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您了解并同意，在此情况下我们有权使用已经去标识化的信息；并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您了解并同意，您在使用我们的产品与</font>/或服务时所提供的所有个人信息，除非您删除或通过系统设置拒绝我们收集，否则将在您使用我们的产品与/或服务期间持续授权我们使用。在您注销账号时，我们将停止使用并删除您的个人信息，具体请详见本隐私政策附件《云淘通账户注销须知》。</span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、我们会对我们的产品与/或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与/或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">5、当我们展示您的信息时，我们会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">6、当我们要将您的信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会实现征求您的明示同意。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您理解，我们向您提供的产品与</font>/或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与/或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与/或服务，但不影响您使用现有产品与/或服务。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">二、我们如何使用</font>Cookies和同类技术</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（一）</font>Cookies的使用</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、为实现您联机体验的个性化需求，使您获得更轻松的访问体验。我们向您发送Cookies是为了简化您重复登录的步骤、存储您的购物偏好或您购物车中的商品等数据进而为您提供购物的偏好设置、帮助您优化对广告的选择与互动、帮助判断您的登录状态以及账户或数据安全。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、我们不会将&nbsp;Cookies&nbsp;用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除Cookies。您可以清除计算机上保存的所有&nbsp;Cookies，大部分网络浏览器会自动接受Cookies，但您通常可根据自己的需要来修改浏览器的设置以拒绝&nbsp;Cookies；另外，您也可以清除软件内保存的所有Cookies。但如果您这么做，您可能需要在每一次访问京东网站时亲自更改用户设置，而且您之前所记录的相应信息也均会被删除，并且可能会对您所使用服务的安全性有一定影响。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（二）网络</font>Beacon和同类技术的使用</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">除</font>Cookies外，我们还会在网站上使用网络Beacon等其他同类技术。我们的网页上常会包含一些（称为"单像素" GIF&nbsp;文件或&nbsp;"网络&nbsp;Beacon"）。我们使用网络Beacon的方式有：</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、通过在云淘通网站上使用网络Beacon，计算用户访问数量，并通过访问&nbsp;Cookies&nbsp;辨认注册的云淘通用户。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、通过得到的Cookies信息，为您提供个性化服务。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">三、我们如何共享、转让、公开披露您的个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（一）共享</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、我们不会与云淘通以外的任何公司、组织和个人共享您的个人信息，但以下情况除外：</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）事先获得您明确的同意或授权；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况下进行提供；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）在法律法规允许的范围内，为维护云淘通、云淘通的关联方或合作伙伴、您或其他云淘通用户或社会公众利益、财产或安全免遭损害而有必要提供；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）只有共享您的个人信息，才能实现我们的产品与/或服务的核心功能或提供您需要的服务；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）应您需求为您处理您与他人的纠纷或争议；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>6）符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>7）基于学术研究而使用；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>8）基于符合法律法规的社会公共利益而使用。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为实现基于云淘通账户向您提供相关产品或服务，向您展示可能感兴趣的内容，保护您的账户与交易安全</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">，我们可能会将您的个人信息与我们的关联方共享。我们将会根据本隐私政策的约定与我们的关联方共享您的个人信息，但我们只会共享必要的个人信息，且受本隐私政策中所声明目的的约束。我们的关联方如要改变个人信息的处理目的，将再次征求您的授权同意。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们的合作伙伴包括以下类型：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）商品或专业技术服务的供应商。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们可能会将您的个人信息共享给支持我们功能的第三方。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付兑换服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与</font>/或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货；当您下单的商品或服务由供应商直接发货或提供服务时，我们需要将您订单中的订购信息和收货信息共享给供应商，以指示其交付商品或提供服务。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）第三方商家。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）委托我们进行推广的合作伙伴。有时我们会代表其他企业向使用我们产品与/或服务的用户群提供促销推广的服务。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们可能会使用您的个人信息以及您的非个人信息集合形成的间接用户画像与委托我们进行推广的合作伙伴（</font>“委托方”）共享，但我们仅会向这些委托方提供推广的覆盖面和有效性的信息，而不会提供您的个人身份信息，或者我们将这些信息进行汇总，以便它不会识别您个人。</span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">比如我们可以告知该委托方有多少人看了他们的推广信息或在看到这些信息后购买了委托方的商品，或者向他们提供不能识别个人身份的统计信息，帮助他们了解其受众或顾客。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、对我们与之共享个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。为了保障数据在第三方安全可控，我们推出了安全服务，在云端服务器提供安全可靠的数据使用和存储环境，确保用户数据的安全性。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数据。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">5、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护云淘通、您或其他云淘通客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过,这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（二）转让</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、事先获得您明确的同意或授权；</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、根据适用的法律法规、法律程序的要求、强制性的行政或司法要求所必须的情况进行提供；</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、符合与您签署的相关协议（包括在线签署的电子协议以及相应的平台规则）或其他的法律文件约定所提供；</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（三）公开披露</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、根据您本人意愿的需求并经公司同意，在您明确同意的披露方式下披露您所指定的个人信息；</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。我们坚信，对于要求我们提供的信息，应该在法律允许的范围内尽可能保持透明。我们对所有的请求都进行了慎重的审查，以确保其具备合法依据，且仅限于执法部门因特定调查目的且有合法权利获取的数据。在法律法规许可的前提下，我们披露的文件均在加密密钥的保护之下。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">四、我们如何保护和保存您的个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（一）我们保护您个人信息的技术与措施</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息：</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、数据安全技术措施</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改</font>,避免数据的损坏或丢失。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通的网络服务采取了传输层安全协议等加密技术，通过</font>https等方式提供浏览服务，确保用户数据在传输过程中的安全。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通采取加密技术对用户个人信息进行加密保存，并通过隔离技术进行隔离。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、</font>SHA256在内多种数据脱敏技术增强个人信息在使用中安全性。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、云淘通为保护个人信息采取的其他安全措施</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通建立数据安全委员会并下设信息保护专职人员、数据安全应急响应组织来推进和保障个人信息安全。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">安全认证和服务。我们存储您个人信息的底层云技术取得了数据中心联盟颁发的</font>“可信云”认证三级认证、通过了公安部安全等级保护三级认证，同时还获得了ISO27000认证。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、我们仅允许有必要知晓这些信息的云淘通及云淘通关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与云淘通的合作关系。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、我们会采取一切合理可行的措施，确保未收集无关的个人信息。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">5、我们还在用户数据保护上做了一些创造性工作在配送体系，采用了独特的“微笑面单”，避免用户敏感信息在配送环节的暴露，同时,大力强化对云淘通物流员工的数据安全培训和要求，提高物流员工保护用户敏感信息的意识。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">6、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过云淘通与第三方进行网上产品与/或服务的交易兑换时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">7、安全事件处置</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">为应对个人信息泄露、损毁和丢失等可能出现的风险，云淘通制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。云淘通也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">8、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">9、如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策</font></span></b><b><u><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			text-decoration:underline;text-underline:single;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">【八、如何联系我们】</font></span></u></b><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">中约定的联系方式联络我们，以便我们采取相应措施。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（二）您个人信息的保存</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理这些个人信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、在您使用我们的产品与/或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、请您注意，当您成功注销云淘通账户后，我们将对您的个人信息进行删除或匿名化处理。当您或我们协助您删除相关信息后，因为适用的法律和安全技术限制，我们可能无法立即从备份系统中删除相应的信息，我们将安全地存储您的个人信息并限制对其的任何进一步的处理，直到备份可以删除或实现匿名化。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">五、您如何管理您的个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">云淘通非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、访问和更正您的个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括：</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）您的账户信息：PC端您可以在“我的云淘通”页面的“账户设置”菜单中查阅您提交给京东的所有个人信息，你也可通过上述途径更新除实名认证信息之外的其他个人信息（您的实名认证信息是您通过实名认证时使用的姓名和身份证信息），如您需要变更您的实名认证信息，您可拨打010-62456788服务热线或QQ客服申请变更。移动端具体路径为：账户名称、个人资料信息：首页--“我的”进入我的云淘通--右上角“设置”进入账号设置—点击头像进入个人信息页账户密码、电话号码、安全信息：首页--“我的”进入我的云淘通--右上角“设置按钮”--账户与安全；兴趣爱好：首页--“我的”进入我的云淘通--商品关注、商品关注、喜欢的内容（具体名称以网页内容为准）。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）您的收货信息：PC端您可以通过访问“我的云淘通”页面的“账户设置”-“收货地址”菜单中随时添加、更改、删除您的收货地址信息（包括收货人姓名、收货地址、收货人的电话号码或邮箱）；移动端具体路径为：首页--“我的”进入我的云淘通--右上角“设置按钮”进入账号设置--地址管理。您也可以将最常用的收货地址设置为默认地址，如此，若您下次购买商品时未更改收货地址，您的商品会配送到该默认地址。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）您的订单信息：PC端您可以通过访问“我的订单”页面查看您的所有已经完成、待付款或待售后的订单。移动端具体路径为：移动端首页--“我的”进入我的云淘通--我的订单/待收款/待收货/退换售后。</span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您可以选择删除已经完成和已取消的订单来删除您的订单信息，但这样可能导致我们无法准确判断您的购买信息而难以提供相应的售后服务，您也无法对删除的订单使用我们的晒单和评价功能。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）您的浏览信息：您可以访问或清除您的搜索历史记录、查看和修改兴趣以及管理其他数据。移动端路径为：搜索历史记录：首页--“我的”进入我的云淘通--浏览记录；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）您的评论信息：QQ客服--待评价--评价中心。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>6）您的发票信息：您可以访问或更新或清除您的发票信息，移动端路径为首页--“我的”进入我的云淘通--右上角“设置”进入账号设置--增票资质。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>7）您的档案信息：您可以在移动端“账户设置-档案”中，主动添加您的爱车型号、宝宝昵称、性别、生日和学龄、设备型号、尺码、健康档案，使您在购买相关产品和服务时更加便捷，也便于我们向您推荐您可能感兴趣的商品。您可通过此路径对您的上述信息进行删除和修改。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>8）对于您在使用我们的产品与/或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>9）您无法访问和更正的个人信息：除上述列明的信息外，您的部分个人信息我们无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、删除您的个人信息</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您在我们的产品与</font>/或服务页面中可以直接清除或删除的信息，包括订单信息、浏览信息、收货地址信息。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在以下情形中，您可以向我们提出删除个人信息的请求：</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）如果我们处理个人信息的行为违反法律法规；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）如果我们收集、使用您的个人信息，却未征得您的同意；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）如果我们处理个人信息的行为违反了与您的约定；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）如果您不再使用我们的产品或服务，或您注销了云淘通账号；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）如果我们终止服务及运营。若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、改变您授权同意的范围或撤回您的授权</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您可以通过删除信息、关闭设备功能、在京东网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、注销账户</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您可以在我们的产品中通过</font>QQ客服直接申请注销账户。请详见《云淘通账户注销须知》。您注销账户后，我们将停止为您提供产品与/或服务，并依据您的要求，除法律法规另有规定外，我们将删除您的个人信息。</span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">5、获取您的个人信息副本</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">6、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消：</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）您可通过PC端账户设置页面的邮件订阅菜单中取消邮件订阅的促销信息。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）您可以根据促销短信内容提示，来取消我们给您发送的手机促销短信。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）您可以通过QQ客服“消息中心-消息设置”设置是否接受我们通过“通知”推动给您的商品和促销信息。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）我们会与第三方的平台或媒体（“平台”）合作基于您的个人信息向您推荐个性化的商品。这些个人信息包括诸如在本网站的产品与/或服务使用情况、访问本网站关联公司网站的情况及您在您的账户设置中填写的兴趣爱好。平台仅向我们提供了展示商品的窗口，窗口内容的链接是京东站内的个性化商品展示信息，由京东进行管理，因此我们不会向广告商提供您的任何个人信息。而且我们在推荐窗内设置了屏蔽功能，您可选择屏蔽您不喜欢的广告。如您不愿意接受京东在单个平台上的推荐服务，请联系平台进行关闭。您知晓并理解平台是自主经营的实体，京东无法对其进行管理。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性取向等相关敏感内容的促销或商品信息给您。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">7、响应您的请求</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与</font>/或服务时所产生的其他个人信息或者获取个人信息副本，或您认为京东存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以发送电子邮件至yuntaotong@yuntaowulian.com或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的15天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在以下情形中，按照法律法规要求，我们将无法响应您的请求：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）与个人信息控制者履行法律法规规定的义务相关的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）与国家安全、国防安全有关的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）与公共安全、公共卫生、重大公共利益有关的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）与犯罪侦查、起诉和审判和执行判决等有关的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）有充分证据表明您存在主观恶意或滥用权利的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>6）出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>7）响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的；</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>8）涉及商业秘密的。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">六、未成年人的个人信息保护</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、云淘通非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用我们的产品与/或服务前，</font></span><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">应事先取得您监护人的同意</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">。云淘通根据国家相关法律法规的规定保护未成年人的个人信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、我们不会主动直接向未成年人收集其个人信息。对于经监护人同意而收集未成年人个人信息的情况，我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与/或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、对于可能涉及的不满14周岁的儿童个人信息，我们进一步采取以下措施予以保障：</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）对于收集到的儿童个人信息，我们除遵守本隐私政策关于用户个人信息的约定外，还会秉持正当必要、知情同意、目的明确、安全保障、依法利用的原则，严格遵循《儿童个人信息网络保护规定》等法律法规的要求进行存储、使用、披露，且不会超过实现收集、使用目的所必须的期限，到期后我们会对儿童个人信息进行删除或匿名化处理。我们会指定专人负责儿童个人信息保护事宜，并设立了儿童个人信息保护的专门邮箱yuntaotong@yuntaowulian.com。我们还会制定儿童个人信息保护的内部专门制度。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）当您作为监护人为被监护的儿童选择使用云淘通相关服务时，我们可能需要向您收集被监护的儿童个人信息，用于向您履行相关服务之必要。在具体服务中需要向您收集儿童个人信息的，我们会事先取得您的授权同意，并告知您收集的目的和用途。如果您不提供前述信息，您将无法享受我们提供的相关服务。此外，您在使用晒单、评价及问答功能分享商品相关信息时可能会主动向我们提供儿童个人信息，请您明确知悉并谨慎选择。您作为监护人应当正确履行监护职责，保护儿童个人信息安全。若儿童本人需要注册或使用我们的产品与/或服务，您应正确引导并予以监护。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）儿童或其监护人有权随时访问和更正儿童个人信息，还可以向我们提出更正和删除的请求。如您对儿童个人信息相关事宜有任何意见、建议或投诉、举报，请联系我们。我们会随时为您提供帮助。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">七、通知和修订</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、为给您提供更好的服务以及随着云淘通业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在云淘通网站、云淘通公众号移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问云淘通以便及时了解最新的隐私政策。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、对于重大变更，我们还会提供更为显著的通知（我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容）。</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">本隐私政策所指的重大变更包括但不限于：</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>1）我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>2）我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>3）个人信息共享、转让或公开披露的主要对象发生变化；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>4）您参与个人信息处理方面的权利及其行使方式发生重大变化；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>5）我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><br></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">（</font>6）个人信息安全影响评估报告表明存在高风险时。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">3、我们还会将本隐私政策的旧版本存档，供您查阅。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">4、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。您可以在云淘通 通过“我的-账户设置-关于云淘通-隐私政策”中查看本政策。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><b><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;font-weight:bold;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">八、如何联系我们</font></span></b><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">1、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">个人信息保护负责人邮箱</font>yuntaotong@yuntaowulian.com</span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">联系电话：【</font>010-62456788】</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">联系地址：北京市海淀区北清路</font>68号院</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">您还可以随时通过访问</font></span><span><a href="https://help.jd.com/"><span class="15" style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;"><font face="黑体">https://</font></span><span class="15" style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;"><font face="黑体">www.yuntaotong</font></span><span class="15" style="mso-spacerun:'yes';font-family:黑体;color:rgb(0,0,0);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;"><font face="黑体">.com</font></span></a></span><span style="mso-spacerun:'yes';font-family:黑体;letter-spacing:0.0000pt;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			mso-font-kerning:0.0000pt;"><font face="黑体">或</font></span><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">在线</font>QQ客服系统或拨打我们的任何一部客服电话等多种方式与我们联系。</span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">2、一般情况下，我们将在30天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">附：个人信息及敏感个人信息示例（非云淘通公众号或网站所收集的个人信息，仅作示例说明）</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:10.0000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><span style="mso-spacerun:'yes';font-family:黑体;color:rgb(134,134,134);
			letter-spacing:0.0000pt;text-transform:none;font-style:normal;
			font-size:10.0000pt;mso-font-kerning:0.0000pt;"><font face="黑体">个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。（本定义出自于《中华人民共和国个人信息保护法》）。</font></span><span style="mso-spacerun:'yes';font-family:黑体;font-size:5.5000pt;
			mso-font-kerning:1.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:left;vertical-align:baseline;"><o:p>&nbsp;</o:p></span></p><p class="MsoNormal"><span style="mso-spacerun:'yes';font-family:黑体;font-size:5.5000pt;
			mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p></div>`
		},
		{
			name: '云淘通商城服务账号注销须知',
			html: `<div class="Section0" style="layout-grid:15.6000pt;"><h3 align="center" style="margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;text-align:center;background:rgb(245,245,245);"><b><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;font-weight:bold;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:0.0000pt;background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">《</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;font-weight:bold;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:0.0000pt;background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;font-weight:bold;
			text-transform:none;font-style:normal;font-size:18.0000pt;
			mso-font-kerning:0.0000pt;background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号注销须知》</font></span></b><b><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;font-weight:bold;
			text-transform:none;font-style:normal;font-size:7.5000pt;
			mso-font-kerning:0.0000pt;"><o:p></o:p></span></b></h3><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">亲爱的</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通会员</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">用户：</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">您在申请注销流程中点击同意前，应当认真阅读《</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号注销须知》（以下称</font><font face="宋体">“</font><font face="黑体">《注销须知》</font><font face="宋体">”</font><font face="黑体">）。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">请您务必审慎阅读、充分理解《注销须知》中相关条款内容，其中包括：</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">1、与您约定免除或限制责任的条款；</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">2、其他以粗体标识的重要条款。</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">如您对本《注销须知》有任何疑问，可拨打我们的客服电话</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">01062456788</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">联系客服，您也可联系</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">QQ</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">在线客服或通过</font><font face="宋体">&nbsp;</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">www.yuntaotong.com</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">自助查询</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">。</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-ascii-font-family:Calibri;
			mso-hansi-font-family:Calibri;mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;
			mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;text-indent:0.0000pt;padding:0pt 0pt 0pt 0pt ;
			mso-pagination:widow-orphan;text-align:justify;text-justify:inter-ideograph;
			background:rgb(245,245,245);"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">【特别提示】当您按照注销页面提示填写信息、阅读并同意本《注销须知》及相关条款与条件且完成全部注销程序后，即表示您已充分阅读、理解并接受本《注销须知》的全部内容。阅读本《注销须知》的过程中，如果您不同意相关任何条款和条件约定，请您立即停止账号注销程序。</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">我们在此善意地提醒您，</font><font face="宋体">&nbsp;</font></span><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">您注销账号的行为会给您的售后维权带来诸多不便，且注销</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">账号后，您的个人信息我们会在</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城的前台系统中去除，使其保持不可被检索、访问的状态，或对其进行匿名化处理。</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="MsoNormal" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;mso-pagination:widow-orphan;text-align:left;"><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:1.0000pt;"><o:p>&nbsp;</o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">1、如果您仍执意注销账号，您的账号需同时满足以下条件：</span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>1）自愿放弃账号在</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城系统中的资产和虚拟权益（包括但不限于余额、</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘豆</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">余额、优惠券、</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">联盟账号权益等），并确保账号中无拍卖</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">兑换</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">保证金、无欠款；</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>2）账号内无未完成的订单和服务；</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>3）在</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城没有过使用该账号激活店铺的记录；</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>4）账号无正在进行中的投诉举报或被投诉举报；</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>5）账号已经解除与其他网站、其他APP的授权登录或绑定关系；</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>6）账号当前为有效账号，非冻结状态。</span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">2、</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号一旦注销，您的个人信息和相关数据将会在商城的前台系统中去除，以后将不可检索、访问，请您在操作之前自行备份</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号相关的所有信息和数据。请您保存好订单商品和服务的交易凭证、票据等资料，否则您有可能须支付额外的账号和订单查询费用，或无法享受售后服务。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">3、如果您的</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号同时是</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">平台商家店铺的绑定账号名，您需先解除相关绑定；</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">4、在</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号注销期间，如果您的</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">账号涉及争议纠纷，包括但不限于投诉、举报、诉讼、仲裁、国家有权机关调查等，</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">有权自行终止本</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">账号的注销而无需另行得到您的同意。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">5、注销</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号，您将无法再使用本账号，也将无法找回您</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号中及与该账号相关的任何内容或信息，包括但不限于：</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>1）您将无法登录、使用本</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通积分</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号；</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>2）本</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号的个人资料和历史信息（包括但不限于用户名、头像、购物记录、关注信息等）都将无法找回，请及时保存、备份；</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>3）您通过</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">账号使用、授权登录或绑定</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">账号后使用的</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">相关或第三方的其他服务的所有记录将无法找回。您将无法再登录、使用前述服务，您曾获得的余额、优惠券、积分、资格、订单、</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">联盟账号权益及其他卡券等视为您自行放弃，将无法继续使用。您理解并同意，</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">无法协助您重新恢复前述服务。请您在提交注销申请前，务必先了解您须解绑的其他相关账号信息，具体可与我们的客服联系。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">（</font>4）您与</span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span><span style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;text-transform:none;
			font-style:normal;font-size:10.0000pt;mso-font-kerning:0.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">平台商家会员的线上绑定关系将同时解除，您已获得的会员权益（含积分、等级等）将同步注销且不可恢复。</font></span><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:10.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p><p class="p" align="justify" style="margin-top:0.0000pt;margin-right:0.0000pt;margin-bottom:0.0000pt;
			margin-left:0.0000pt;padding:0pt 0pt 0pt 0pt ;mso-pagination:widow-orphan;
			text-align:justify;text-justify:inter-ideograph;"><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);">6、注销本</span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号并不代表本</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">云淘通</font></span></b><b style="mso-bidi-font-weight:normal"><span class="15" style="mso-spacerun:'yes';font-family:黑体;mso-hansi-font-family:宋体;
			color:rgb(0,0,0);letter-spacing:0.0000pt;mso-ansi-font-weight:bold;
			text-transform:none;font-style:normal;font-size:10.0000pt;
			background:rgb(245,245,245);mso-shading:rgb(245,245,245);"><font face="黑体">商城服务账号注销前的账号行为和相关责任得到豁免或减轻。</font></span></b><span style="mso-spacerun:'yes';font-family:Calibri;mso-fareast-font-family:宋体;
			mso-bidi-font-family:'Times New Roman';font-size:5.0000pt;mso-font-kerning:0.0000pt;"><o:p></o:p></span></p></div>`
		},
		{
			name: '小米帐号隐私政策',
			linkUrl: 'https://static.account.xiaomi.com/html/privacy/account/zh_CN.html'
		}
	],
	
}
